<template>
  <v-card class="pa-6">
    <template v-if="usuarios && usuarios.length">
      <v-card-title>
        <div class="flex-grow-1"></div>
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="search"
          :label="$tc('global.buscar')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="usuarios"
        :items-per-page="10"
        :sort-by="['id']"
        :search="search"
        class="data-tables data-tables__row-click"
        @click:row="goToUsuario"
      ></v-data-table>
    </template>
    <NaoEncontrado v-else-if="usuarios && usuarios.length === 0" />
    <v-skeleton-loader
      v-else-if="!usuarios || loading"
      class="mx-auto"
      type="table"
    ></v-skeleton-loader>
  </v-card>
</template>

<script>
import { getUsuarios } from "@/services/api/usuarios.api.js";
import { mapState } from "vuex";

export default {
  name: "ListaUsuarios",
  data: () => ({
    search: "",
    loading: true,
    usuarios: null,
  }),
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    headers() {
      let headers = [
        {
          text: this.$tc("global.nome"),
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Whats",
          value: "whats",
        },
        {
          text: this.$tc("columns.celular"),
          value: "celular",
        },
        {
          text: "Status",
          value: "status",
        },
      ];
      return headers;
    },
  },
  methods: {
    goToUsuario(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.name);
      this.$router.push({
        name: "Usuario",
        params: { usuarioId: item.id },
      });
    },
    getUsuarios() {
      this.loading = true;

      getUsuarios()
        .then((response) => {
          this.usuarios = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getUsuarios();
  },
};
</script>

<style scoped lang="scss"></style>
